import React from "react"

import Reveal from "react-reveal/Reveal"

import styles from "./styles.module.less"

const Dwp = () => {
  return (
    <div className={styles.Dwp}>
      <Reveal>
        <h2>Stay-tuned we&#39;re up to something again</h2>
      </Reveal>
    </div>
  )
}

export default Dwp

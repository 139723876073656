import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Dwp from "../components/Dwp"

const AboutPage = () => (
  <Layout backgroundColor={"white"} navColor={"black"}>
    <SEO title="Design Week Portland" keywords={[]} description="" />
    <Dwp />
  </Layout>
)

export default AboutPage
